import axios from 'axios'

import { NO_RESULTS } from '@/constants/errorHandling/error-messages.js'
import { API_SERVER } from '@/constants/index.js'
import { logError } from '@/utils/error-handling.js'

const validateResponseData = (data) => {
	if (!typeof data.nodes === 'object' || !data.nodes[-1]) {
		throw new Error(NO_RESULTS, data)
	}
}

export const fetchBusinesses = async ({ bounds, route }) => {
	try {
		const filters = route.fullPath
			.substring(1)
			.replace(/\/|\+/g, ',')

		const endpointUrl = `${API_SERVER}/maps.php?p=${bounds}&filters=${filters}&page=${route.params.page}`

		const { data } = await axios.post(endpointUrl)

		validateResponseData(data)

		return data.nodes[-1].c
	} catch (e) {
		logError(e)
	}
}

export const fetchBusiness = async id => {
	try {
		const { data } = await axios.get(`${API_SERVER}/api/Business/get?business_id=${id}`)
		if (!data.results) {
			throw new Error('no results found')
		}
		return data
	} catch (e) {
		logError(e)
	}
}
